import productData from '@/data/products.json'; // WHY DO WE HAVE 2 PRODUCTS.JSON FILES?
import optionsData from '@/data/options.json';
// import bannerData from '@/data/banner.json'

const getDefaultState = () => {
  return {
    price_label: '',
    defaultPriceLabel: 'TEFLORG',
    added: [],
    showAdded:{error:false,id:null,message:'',show:false},
    discount: '',
    orderId: null,
    cartUser: null,
    totals: {full:0,discounted:0,due:0,paid:0},
    error:{type:'',message:'',show:false},
    status: '0',
    serverCart: {},
    cartLoading: false,
    regionClicked: '',
  }
}

const f = {
  'TEFLORG': "GBP",
  'TEFLUS': "USD",
  'IRELAND': "EUR",
  'CANADA': "CAD",
  'AUSTRALIA': "AUD",
  'SOUTHAFRICA': "ZAR"
}

const switchCourse = {
  '20140': 26, // 20-hr -> 140
  '30150': 27, // 30-hr -> 150
  '20220': 10184, // 20-hr -> 220
  '30230': 10185, // 30-hr -> 230
  '20140v': 10168, // 20-hrv -> 140v
  '30150v': 10172, // 30-hrv -> 150v
  '20220v': 10186, // 20-hrv -> 220v
  '30230v': 10187, // 30-hrv -> 230v
}

export const state = () => getDefaultState()

export const mutations = {
  setPriceLabel(state, price_label) {
    console.log('Setting pricelabel', price_label)
    state.price_label = price_label
    //  dispatch('getProducts')
  },
  setRegionClicked(state, regionClicked) {
    console.log('Setting regionClicked', regionClicked)
    state.regionClicked = regionClicked
    //  dispatch('getProducts')
  },
  setDefaultPriceLabel(state, price_label) {
    console.log('Setting default pricelabel', price_label)
    state.defaultPriceLabel = price_label
    //  dispatch('getProducts')
  },
  addToCart(state, { id }) {
    console.log('addToCart mutation: ' ,id)
    const record = state.added.find(p => p.id === id)
    if (!record) {
      state.added.push({
        id
      })
      state.showAdded.error = false
      state.showAdded.show = true;
      state.showAdded.id = id;
      state.showAdded.message = 'Succesfully added to cart';
    } else {
      // Check item isn't already in the cart
      console.log('Other addToCart error: ' ,record, id)
      state.showAdded.show = false;
      state.showAdded.id = id;
      state.showAdded.error = true
      state.showAdded.message = 'You already have this item in your cart';
    }
  },
  addToCartFree(state, { id }) {
    console.log('addToCart free mutation: ' ,id)
    const record = state.added.find(p => p.id === id)
    if (!record) {
      state.added.push({
        id
      })
      state.showAdded.error = false
      state.showAdded.show = true;
      // state.showAdded.id = id;
      state.showAdded.message = 'Succesfully added to cart';
    } else {
      // Check item isn't already in the cart
      console.log('Other addToCart error: ' ,record, id)
      state.showAdded.show = false;
      // state.showAdded.id = id;
      state.showAdded.error = true
      state.showAdded.message = 'You already have this item in your cart';
    }
  },
  setCartError(state, data) {
    console.log('Set cart error: ' ,data)
    state.showAdded.show = false;
    state.showAdded.id = data.id;
    state.showAdded.error = true
    state.showAdded.message = data.error;
  },
  removeFromCart(state, id) {
    console.log('removing: ',id)
    state.added = state.added.filter(item => item.id !== id)
  },
  hideAdded(state) {
    state.showAdded.show = false;
    state.showAdded.error = false;
    state.showAdded.id = null;
    state.showAdded.message = '';
  },
  addDiscount(state, discount) {
    state.discount = discount
  },
  removeDiscount(state) {
    state.discount = ''
  },
  setOrderId(state,orderId) {
    state.orderId = orderId
  },
  setStatus(state,status) {
    state.status = status
  },
  setCartUser(state,userId) {
    state.cartUser = userId
  },
  setCartLoading(state,loading) {
    state.cartLoading = loading
  },
  setCartTotals(state,data) {
    console.log('totals: ',data)
    state.totals.full = data.full
    state.totals.discounted = data.discounted
    state.totals.due = data.due
    state.totals.paid = data.paid
  },
  setServerCart(state,data){
    state.serverCart = data
  },
  resetCart (state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    // Object.assign(state, getDefaultState())
    // Updated this so we keep priceLabel and cartUser if they are set - JAKE
    state.added = []
    state.showAdded.show = false;
    state.showAdded.error = false;
    state.showAdded.id = null;
    state.showAdded.message = '';
    state.discount = ''
    state.orderId = null
    state.totals.full = 0
    state.totals.discounted = 0
    state.totals.due = 0
    state.totals.paid = 0
    state.error.type = ''
    state.error.show = false
    state.error.message = ''
    state.status = '0'
    state.serverCart = {}
    state.cartLoading = false
    state.regionClicked = ''
  },
  fullReset (state){
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState())
  },
  setError (state,errorMessage){
    state.error.type = errorMessage.type
    state.error.show = true
    state.error.message = errorMessage.message
  },
  hideError (state){
    state.error.type = ''
    state.error.show = false
    state.error.message = ''
  }
}
export const getters = {
  getPriceLabel: state => {
    if(state.price_label !== ''){
      return state.price_label
    } else {
      return state.defaultPriceLabel
    }
  },
  getRegionClicked: state => {
    if(state.regionClicked !== ''){
      return state.regionClicked
    } else if(state.price_label !== ''){
      return state.price_label
    } else {
      return state.defaultPriceLabel
    }
  },
  getDiscount: state => {
    return state.discount.toUpperCase()
  },
  getTotals: state => {
    return state.totals
  },
  getOrderId: state => {
    return state.orderId
  },
  getStatus: state => {
    return state.status
  },
  getLoading: state => {
    return state.cartLoading
  },
  cartProducts: state => {
    return state.added.map(({
      id
    }) => {
      const product = productData.find(p => p.id === id)
      // console.log('Product: ',product)
      const productObject = {
        id:product.id,
        name: product.name,
        description:product.description,
        prices: product.prices,
      }
      // Push in the default in case we don't have regional pricing - now done in staticdata.js
      /*
      productObject.price.push({
        label:'DEFAULT',
        cost: product.cost
      })
      */
      return  productObject
    })
  },
  getServerCart: state => {
    return state.serverCart
  },
  showAdded: state => {
    return {
      show:state.showAdded.show,
      id:state.showAdded.id,
      message:state.showAdded.message,
      error:state.showAdded.error
    }
  },
  getErrorStatus: state => {
    console.log(
      'type: ',state.error.type,
      'show: ',state.error.show,
      'message: ',state.error.message
    )
    return {
      type: state.error.type,
      show: state.error.show,
      message: state.error.message
    }
  }
}

export const actions = {
  addToCart({commit}, product) {
    commit('addToCart', {
      id: product.id
    })
  },
  addToCartFree({commit}, product) {
    commit('addToCartFree', {
      id: product.id
    })
  },
  setCartError({commit}, data) {
    console.log('setCartError action: ',data)
    commit('setCartError', {
      id: data.id,
      error: data.error
    })
  },
  addDonation({commit}, id) {
    commit('addToCart', {
      id
    })
  },
  addDiscount({commit}, discount) {
    commit('addDiscount', discount)
  },
  setPriceLabel({commit}, price_label) {
    commit('setPriceLabel', price_label)
  },
  setRegionClicked({commit}, regionClicked) {
    commit('setRegionClicked', regionClicked)
  },
  setDefaultPriceLabel({commit}, price_label) {
    commit('setDefaultPriceLabel', price_label)
  },
  setCartLoading({commit}, loading) {
    commit('setCartLoading', loading)
  },
  setCartFromParams({commit}, data) {
    const totalsData = {
      full:data.boughtProductTotal,
      discounted:data.boughtProductTotalDiscounted,
      due:data.totalDue,
      paid:data.totalPaid
    }
    commit('setCartTotals', totalsData)
    commit('setOrderId', data.id)
    commit('setPriceLabel', data.productPriceLabel)
    commit('setCartUser', data.customerId)
    // Loop through and add products
    for(const boughtProduct of data.boughtProducts) {
      commit('addToCart', {
        id: boughtProduct.productId
      })
    }
    commit('setStatus', data.status)
    commit('setServerCart',data)
  },
  updateServerCart({commit}, data) {
    commit('setServerCart',data)
  },
  resetCart({commit}) {
    console.log('Reset Cart')
    commit('resetCart')
  },
  fullReset({commit}) {
    console.log('Fully reset Cart')
    commit('fullReset')
  },
  hideError({commit}) {
    commit('hideError')
  },

  // SERVER CALLS

  // Create cart on server
  async createCartServer({ commit }){

    console.log('Create cart on server called')
    commit('hideError')
    const cartData = {
      product_price_label: this.state.cart.price_label !== '' ? this.state.cart.price_label : this.state.cart.defaultPriceLabel
    };

    // IF USER IS ALREADY LOGGED IN
    if(this.state.user.user_id !== ''){
      console.log('User ID exists')
      cartData.customer_id = this.state.user.user_id

      try {
        const result = await this.$axios.$post(
          process.env.DATA_URL + '/orders/create/',
          cartData
        )
        console.log('create cart with customer: ',result)
        commit('setOrderId', result.id)
        commit('setCartUser', result.customer_id)
        return result
      } catch (error) {
        const errorMessage = {
          type: 'cart',
          message: error.response.data
        }
        commit('setError', errorMessage)
        return error.response.data
      }

    // IF USER IS NOT LOGGED IN
    } else {
      console.log('No User ID')
      try {
        const result = await this.$axios.$post(
          process.env.DATA_URL + '/orders/create/no-customer/',
          cartData
        )
        console.log('create cart without customer: ',result)
        commit('setOrderId', result.id)
        return result
      } catch (error) {
        const errorMessage = {
          type: 'cart',
          message: error.response.data
        }
        commit('setError', errorMessage)
        return error.response.data
      }

    }

  },

  // Create cart on server
  async getCartServer({ commit }){

    console.log('Get cart on server called')
    commit('hideError')
    const orderId = this.state.cart.orderId;

    try {
      const result = await this.$axios.$get(
        process.env.DATA_URL + '/orders/get/'+orderId
      )
      console.log('get cart from server: ',result)
      commit('setServerCart', result)
      return result
    } catch (error) {
      const errorMessage = {
        type: 'cart',
        message: error.response.data
      }
      commit('setError', errorMessage)
      return error.response.data
    }

  },

  async addProductToCart({ dispatch, commit }, data){
    console.log('addProductToCart',data)
    const isBundle = data.isBundle
    const orderId = data.cartData.order_id;
    const priceData = data.priceData
    const cartData = data.cartData
    const url = isBundle ? process.env.DATA_URL + '/orders/add_bundle/' : process.env.DATA_URL + '/orders/add_product/'

    try {
      const result = await this.$axios.$post(
        url,
        cartData
      )
      console.log('Add to cart on server: ',result)
      await dispatch('addToCart',priceData.product)
      // Update the cart totals
      const totalsData = {
        full:result.boughtProductTotal,
        discounted:result.boughtProductTotalDiscounted,
        due:result.totalDue,
        paid:data.totalPaid
      }
      commit('setCartTotals', totalsData)
      commit('setServerCart', result)

      // TRACKING
      if ( window ) {
        const currencyCode = f[this.state.cart.price_label]
        const pricing = priceData.pricesDiscounted.filter(price => price.label === this.state.cart.price_label)
        console.log('PRICING',pricing)
        // Track via rudderstack
        const trackingData = {
          "product_id": priceData.product.id,
          "sku": priceData.sku,
          "category": priceData.product.type,
          "name": priceData.product.name,
          // "brand": "Gamepro",
          // "variant": "111",
          "price": pricing[0].cost,
          "discount": pricing[0].discountAmount,
          "quantity": 1,
          "coupon": priceData.offerCode,
          "currency": currencyCode,
          // "position": 1,
          // "url": "https://www.website.com/product/path",
          // "image_url": "https://www.website.com/product/path.png",
        }
        // Tracking data for GTM
        const trackingDataGTM = {
          "item_id": priceData.product.id,
          "sku": priceData.sku,
          "item_category": priceData.product.type,
          "item_name": priceData.product.name,
          "price": pricing[0].cost,
          "discount": pricing[0].discountAmount,
          "quantity": 1,
          "coupon": priceData.offerCode,
        }
        const items = []
        items.push(trackingDataGTM)

        this.$rs.track("Product Added",
          trackingData
        )
        // Track via GTM
        this.$gtm.push({
          event: "add_to_cart",
          currency: currencyCode,
          value: pricing[0].cost,
          items
        })
      }

      // Auto apply discount
      if(priceData.offerCode !== '' && this.state.cart.discount !== priceData.offerCode){
        console.log('Auto-applying discount...',priceData.offerCode)
        await dispatch('addDiscountServer',priceData.offerCode);
      }

      // Add free product if one is included in offer and not already in cart
      const FP = this.state.cart.added.find(p => p.id === Number(optionsData.freeproduct_id))
      if(optionsData.free_offer === '1' && optionsData.freeproduct_id && (priceData.product.type.includes('course') || priceData.product.type.includes('bundle')) && !FP) {
        commit('setCartLoading', true)
        const freeproductData = {
          order_id: orderId,
          product_id: Number(optionsData.freeproduct_id),
        }
        const productId = {
          id: Number(optionsData.freeproduct_id) 
        }
        
        // Call add again for free product
        const freeResult = await this.$axios.$post(
          process.env.DATA_URL + '/orders/add_product/',
          freeproductData
        )
        console.log('Adding free product...'. freeproductData)
        await dispatch('addToCartFree',productId)
        // Update the cart totals
        const freeTotalsData = {
          full:freeResult.boughtProductTotal,
          discounted:freeResult.boughtProductTotalDiscounted,
          due:freeResult.totalDue,
          paid:data.totalPaid
        }
        commit('setCartLoading', false)
        commit('setCartTotals', freeTotalsData)
        commit('setServerCart', freeResult)
      }

      
      commit('setCartLoading', false)
      return result

    } catch (error) {
      console.log('Add to cart error: ',error)
      await dispatch('setCartError',{
        id: priceData.product.id,
        error: error.response.data
      })
      return error.response.data
    }
  },

  // Add to cart on server
  async addToCartServer({ dispatch, commit }, priceData){
    localStorage.removeItem('tefl_cs_store')
    console.log('Add to cart on server called',priceData)
    console.log('Actions: ',actions)
    console.log('classroom',priceData.product.classroomId)
    console.log('relId',priceData.product.relId)
    commit('hideError')
    commit('setCartLoading', true)
    const orderId = this.state.cart.orderId;
    const productId = priceData.product.id
    // const courseId = priceData.product.courseId
    const classroomId = priceData.product.classroomId
    const relativeOrderBoughtProductId = priceData.product.relId

    // HANDLE BUNDLE PRODUCTS
    // Array of classrooms (may need updating if bundle products change)
    const classroomArray = [10192,10161,10166,10171]
    let hasClassroom = false
    const isBundle = priceData.product.isBundle
    const productArray = []
    const data = {}
    data.isBundle = false;

    // Create the data to send
    const cartData = {
      order_id: orderId,
      product_id: productId
    };

    if(isBundle){
      productArray.push(cartData)
      priceData.product.bundleProducts.forEach((bundleProduct) => {
        // Create the data to send
        const bundleCartData = {
            order_id: orderId,
            product_id: bundleProduct
        };        // If it's a classroom course, add the flexible classroom course ID
        if (classroomArray.includes(bundleProduct)) {
          hasClassroom = true
        }
        productArray.push(bundleCartData)
      })
    }

    // If it's an extension, add the relative product ID
    if (relativeOrderBoughtProductId !== null) {
      cartData.relative_order_bought_product_id = relativeOrderBoughtProductId;
    }

    // If it's a classroom course, add the classroom course ID
    if (classroomId !== null) {
      cartData.classroom_id = classroomId;
    }

    // If the bundle contains a classroom we need to add the flexible classroom to the cartData
    if (isBundle && hasClassroom) {
      cartData.classroom_id = Number(optionsData.flexible_id);
    }

    // For gift vouchers
    if(priceData.voucherData !== undefined){
      console.log('Gift voucher added!')
      cartData.voucher_name = priceData.voucherData.voucher_name
      cartData.voucher_email = priceData.voucherData.voucher_email
      cartData.voucher_message = priceData.voucherData.voucher_message
      cartData.voucher_date = priceData.voucherData.voucher_date
      cartData.voucher_sender = priceData.voucherData.voucher_sender
    }

    // Check if this product is already in the cart
    const record = this.state.cart.added.find(p => p.id === productId)
    // Check if classroom is already in the cart
    // const classroomRecord = this.state.cart.added.find(p => p.classroomId === classroomId)
    console.log('record: ',record)

    // If not in cart let's add it
    if (!record) {

      // Do we already have a cart setup?
      if(orderId !== null){
        
        console.log('Order ID exists')

        // HANDLE BUNDLE PRODUCTS
        if(productArray.length > 0){
          data.isBundle = true
          data.cartData = cartData
          data.priceData = priceData
          await dispatch('addProductToCart',data)
          // for (const bundleData of productArray){
          //   data.cartData = bundleData
          //   data.priceData = priceData
          //   await dispatch('addProductToCart',data)
          // }
        } else {
            data.cartData = cartData
            data.priceData = priceData
            await dispatch('addProductToCart',data)
        }

      } else {

        console.log('No order ID')

        // Cart doesn't exist yet, let's create one
        const orderCreated = await dispatch('createCartServer')
        cartData.order_id = orderCreated.id
        
        // HANDLE BUNDLE PRODUCTS
        if(productArray.length > 0){
          data.isBundle = true
          data.cartData = cartData
          data.priceData = priceData
          await dispatch('addProductToCart',data)
          // for (const bundleData of productArray){
          //   bundleData.order_id = orderCreated.id
          //   data.cartData = bundleData
          //   data.priceData = priceData
          //   await dispatch('addProductToCart',data)
          // }
        } else {
            data.cartData = cartData
            data.priceData = priceData
            await dispatch('addProductToCart',data)
        }

      }
    
    } else {
      // Item is already in the cart
      console.log('Item already in cart',priceData.product.id)
      // Do this anyway to update the message
      // commit('addToCart',priceData.product.id)
      await dispatch('addToCart',priceData.product)
    }
    
  },

  async removeProductFromCart({commit}, data){
    console.log('removeProductFromCart',data)
    const cartData = data
    const productId = data.product_id
    try {
      const result = await this.$axios.$post(
        process.env.DATA_URL + '/orders/remove_product/',
        cartData
      )
      console.log('Remove from cart on server: ',result,productId)
      commit('removeFromCart', productId)
      // Update the cart totals
      const totalsData = {
        full:result.boughtProductTotal,
        discounted:result.boughtProductTotalDiscounted,
        due:result.totalDue,
        paid:data.totalPaid
      }
      commit('setCartTotals', totalsData)
      commit('setServerCart', result)

      // Track via rudderstack
      this.$rs.track("Product Removed", {
        "product_id": productId,
        "sku": productId,
        "quantity": 1,
      })

      // Track via GTM
      // NEEDS LOOKING AT
      this.$gtm.push({
        event: "remove_from_cart",
        data: {
          "product_id": productId,
          "sku": productId,
          "quantity": 1,
        }
      })

      return result

    } catch (error) {
      const errorMessage = {
        type: 'cart',
        message: error.response.data
      }
      commit('setError', errorMessage)
      return error.response.data
    }
  },

  // Remove from cart on server
  async removeFromCartServer({ dispatch, commit }, product){
    console.log('Remove from cart',product)
    localStorage.removeItem('tefl_cs_store')
    commit('hideError')
    const orderId = this.state.cart.orderId;
    const productId = product.id

    // HANDLE BUNDLE PRODUCTS
    // const isBundle = product.isBundle
    const productArray = []

    // Create the data to send
    const cartData = {
      order_id: orderId,
      product_id: productId,
    };

    // if(isBundle){
    //   productArray.push(cartData)
    //   product.bundleProducts.forEach((bundleProduct) => {
    //     // Create the data to send
    //     const bundleCartData = {
    //         order_id: orderId,
    //         product_id: bundleProduct
    //     };
    //     productArray.push(bundleCartData)
    //   })
    // }



    // HANDLE BUNDLE PRODUCTS
    if(productArray.length > 0){
      for (const bundleData of productArray){
        console.log('Removing bundle product...',bundleData)
        await dispatch('removeProductFromCart',bundleData)
      }
    } else {
        await dispatch('removeProductFromCart',cartData)
    }

    
  },

  // Assign user to cart on server
  async assignCartServer({ commit }){
    localStorage.removeItem('tefl_cs_store')
    commit('hideError')
    const orderId = this.state.cart.orderId;
    const customerId = this.state.user.user_id;


    if(orderId != null){

      // Create the data to send
      const cartData = {
          order_id: orderId,
          customer_id: customerId,
      };

      try {
        const result = await this.$axios.$post(
          process.env.DATA_URL + '/orders/assign/',
          cartData
        )
        console.log('Assign cart on server: ',result)
        commit('setCartUser', result.customer_id)
        commit('setServerCart', result)
        return result

      } catch (error) {
        const errorMessage = {
          type: 'cart',
          message: error.response.data
        }
        commit('setError', errorMessage)
        return error.response.data
      }

    } else {
        return 'No order to assign'
    }
  },

  // Use a gift voucher
  async applyGiftVoucher({ dispatch, commit }, voucherCode){
    localStorage.removeItem('tefl_cs_store')
    // console.log('Applying discount: ',discountCode)
    commit('hideError')
    commit('setCartLoading', true)
    const orderId = this.state.cart.orderId;

    // Create the data to send
    const cartData = {
        order_id: orderId,
        voucher_code: voucherCode
    };

    // console.log('cartData: ', cartData)

    try {
      const result = await this.$axios.$post(
        process.env.DATA_URL + '/orders/add_voucher/',
        cartData
      )
      // TRACKING
      if ( window ) {
        // Track via rudderstack
        this.$rs.track("Coupon Entered", {
            order_id: cartData.order_id,
            cart_id: cartData.order_id,
            coupon_id: cartData.voucher_code,
        })
        // NO GTM EQUIVALENT FOR THIS
      }
      console.log('Add gift voucher on server: ',result)
      // await dispatch('addDiscount',voucherCode)
      // Update the cart totals
      const totalsData = {
        full:result.boughtProductTotal,
        discounted:result.boughtProductTotalDiscounted,
        due:result.totalDue,
        paid:result.totalPaid
      }
      commit('setCartTotals', totalsData)
      commit('setServerCart', result)

      // TRACKING
      // const canTrack = await this.$cookies.get('rl_anonymous_id');
      if ( window ) {
        // Track via rudderstack
        this.$rs.track("Coupon Applied", {
            order_id: orderId,
            cart_id: orderId,
            coupon_id: voucherCode,
            coupon_name: voucherCode,
            discount: result.boughtProductTotal - result.boughtProductTotalDiscounted
        })
        // NO GTM EQUIVALENT FOR THIS
      }
      commit('setCartLoading', false)
      return result

    } catch (error) {
      console.log('voucher error: ',error)
      const errorMessage = {
        type: 'discount',
        message: error.response.data
      }
      commit('setError', errorMessage)
      // TRACKING
      if ( window ) {
        // Track via rudderstack
        this.$rs.track("Coupon Denied", {
            order_id: orderId,
            cart_id: orderId,
            coupon: voucherCode,
            reason: errorMessage.message
        })
        // NO GTM EQUIVALENT FOR THIS
      }
      commit('setCartLoading', false)
      return error.response.data
    }
  },

  // Add discount on server
  async addDiscountServer({ dispatch, commit }, discountCode){
    localStorage.removeItem('tefl_cs_store')
    // console.log('Applying discount: ',discountCode)
    commit('hideError')
    commit('setCartLoading', true)
    const orderId = this.state.cart.orderId;

    // Create the data to send
    const cartData = {
        order_id: orderId,
        discount_code: discountCode
    };

    // console.log('cartData: ', cartData)

    try {
      const result = await this.$axios.$post(
        process.env.DATA_URL + '/orders/add_discount/',
        cartData
      )
      // TRACKING
      if ( window ) {
        // Track via rudderstack
        this.$rs.track("Coupon Entered", {
            order_id: cartData.order_id,
            cart_id: cartData.order_id,
            coupon_id: cartData.discount_code,
        })
        // NO GTM EQUIVALENT FOR THIS
      }
      console.log('Add discount on server: ',result)
      await dispatch('addDiscount',discountCode)
      // Update the cart totals
      const totalsData = {
        full:result.boughtProductTotal,
        discounted:result.boughtProductTotalDiscounted,
        due:result.totalDue,
        paid:result.totalPaid
      }
      commit('setCartTotals', totalsData)
      commit('setServerCart', result)

      // Add free product if one is present
      if(discountCode.toUpperCase() === 'PODCAST' || discountCode.toUpperCase() === 'GOABROAD') {
        commit('setCartLoading', true)
        const freeproductData = {
          order_id: cartData.order_id,
          product_id: Number(optionsData.freeproduct_id), // THIS MUST BE UPDATED FOR LIVE
        }
        const productId = {
          id: Number(optionsData.freeproduct_id), // THIS MUST BE UPDATED FOR LIVE
        }

        // Call add again for free product
        const freeResult = await this.$axios.$post(
          process.env.DATA_URL + '/orders/add_product/',
          freeproductData
        )
        console.log('Adding free product...'. freeproductData)
        await dispatch('addToCartFree',productId)
        // Update the cart totals
        const freeTotalsData = {
          full:freeResult.boughtProductTotal,
          discounted:freeResult.boughtProductTotalDiscounted,
          due:freeResult.totalDue,
          paid:result.totalPaid
        }
        commit('setCartLoading', false)
        commit('setCartTotals', freeTotalsData)
        commit('setServerCart', freeResult)
      }

      // TRACKING
      // const canTrack = await this.$cookies.get('rl_anonymous_id');
      if ( window ) {
        // Track via rudderstack
        this.$rs.track("Coupon Applied", {
            order_id: orderId,
            cart_id: orderId,
            coupon_id: discountCode,
            coupon_name: discountCode,
            discount: result.boughtProductTotal - result.boughtProductTotalDiscounted
        })
        // NO GTM EQUIVALENT FOR THIS
      }
      commit('setCartLoading', false)
      return result

    } catch (error) {
      console.log('discount error: ',error)
      const errorMessage = {
        type: 'discount',
        message: error.response.data
      }
      commit('setError', errorMessage)
      // TRACKING
      if ( window ) {
        // Track via rudderstack
        this.$rs.track("Coupon Denied", {
            order_id: orderId,
            cart_id: orderId,
            coupon: discountCode,
            reason: errorMessage.message
        })
        // NO GTM EQUIVALENT FOR THIS
      }
      commit('setCartLoading', false)
      return error.response.data
    }
  },

  // Switch to combined course when upgrading
  async switchProductServer({ dispatch, commit }, priceData){
    localStorage.removeItem('tefl_cs_store')
    console.log('currentProduct',priceData.currentProductId)
    console.log('Add to cart on server called',priceData)
    console.log('Actions: ',actions)
    console.log('classroom',priceData.product.classroomId)
    console.log('relId',priceData.product.relId)
    commit('hideError')
    commit('setCartLoading', true)
    const orderId = this.state.cart.orderId;
    const productId = priceData.product.id;
    let productSwitch = null

    // Find out what product to switch to
    if (priceData.currentProductId === 25){
      switch (productId) {
        case 1:
          productSwitch = '20140'
          break;
        case 8:
            productSwitch = '30150'
            break;
        case 10166:
            productSwitch = '20140v'
            break;
        case 10171:
            productSwitch = '30150v'
            break;
        default:
          console.log('Sorry course does not exist');
      }
    } else if(priceData.currentProductId === 10183){
      switch (productId) {
        case 1:
          productSwitch = '20220'
          break;
        case 8:
            productSwitch = '30230'
            break;
        case 10166:
            productSwitch = '20220v'
            break;
        case 10171:
            productSwitch = '30230v'
            break;
        default:
          console.log('Sorry course does not exist');
      }
    }

    const newProduct = {id: switchCourse[productSwitch]};
    const removeProduct =  {id: priceData.currentProductId};
    const classroomId = priceData.product.classroomId
    const relativeOrderBoughtProductId = priceData.product.relId

    // Create the data to send
    const cartData = {
        order_id: orderId,
        product_id: newProduct.id
    };

    // If it's an extension, add the relative product ID
    if (relativeOrderBoughtProductId !== null) {
      cartData.relative_order_bought_product_id = relativeOrderBoughtProductId;
    }

    // If it's a classroom course, add the classroom course ID
    if (classroomId !== null) {
      cartData.classroom_id = classroomId;
    }

    // Create the data to send
    const removeCartData = {
      order_id: orderId,
      product_id: removeProduct.id
  };

    // Check if this product is already in the cart
    const record = this.state.cart.added.find(p => p.id === productId)
    // Check if classroom is already in the cart
    // const classroomRecord = this.state.cart.added.find(p => p.classroomId === classroomId)
    console.log('record: ',record)

    // If not in cart let's add it
    if (!record) {

      // Do we already have a cart setup?
      if(orderId !== null){

        try {
          const result = await this.$axios.$post(
            process.env.DATA_URL + '/orders/remove_product/',
            removeCartData
          )
          console.log('Remove from cart on server: ',result,removeProduct.id)
          commit('removeFromCart', removeProduct.id)
          // Update the cart totals
          const totalsData = {
            full:result.boughtProductTotal,
            discounted:result.boughtProductTotalDiscounted,
            due:result.totalDue,
            paid:result.totalPaid
          }
          commit('setCartTotals', totalsData)
          commit('setServerCart', result)
    
        } catch (error) {
          const errorMessage = {
            type: 'cart',
            message: error.response.data
          }
          commit('setError', errorMessage)
        }

        console.log('Product removed, adding new one', removeProduct)
        
        console.log('Order ID exists')
          try {
            const result = await this.$axios.$post(
              process.env.DATA_URL + '/orders/add_product/',
              cartData
            )
            console.log('Add to cart on server: ',result)
            await dispatch('addToCart',newProduct)
            // Update the cart totals
            const totalsData = {
              full:result.boughtProductTotal,
              discounted:result.boughtProductTotalDiscounted,
              due:result.totalDue,
              paid:result.totalPaid
            }
            commit('setCartTotals', totalsData)
            commit('setServerCart', result)

            // TRACKING
            if ( window ) {
              const currencyCode = f[this.state.cart.price_label]
              const pricing = priceData.pricesDiscounted.filter(price => price.label === this.state.cart.price_label) || [priceData.price]
              // Track via rudderstack
              const trackingData = {
                "product_id": priceData.product.id,
                "sku": priceData.sku,
                "category": priceData.product.type,
                "name": priceData.product.name,
                // "brand": "Gamepro",
                // "variant": "111",
                "price": pricing[0].cost,
                "discount": pricing[0].discountAmount,
                "quantity": 1,
                "coupon": priceData.offerCode,
                "currency": currencyCode,
                // "position": 1,
                // "url": "https://www.website.com/product/path",
                // "image_url": "https://www.website.com/product/path.png",
              }
              // Tracking data for GTM
              const trackingDataGTM = {
                "item_id": priceData.product.id,
                "sku": priceData.sku,
                "item_category": priceData.product.type,
                "item_name": priceData.product.name,
                "price": pricing[0].cost,
                "discount": pricing[0].discountAmount,
                "quantity": 1,
                "coupon": priceData.offerCode,
              }
              const items = []
              items.push(trackingDataGTM)

              this.$rs.track("Product Added",
                trackingData
              )
              // Track via GTM
              this.$gtm.push({
                event: "add_to_cart",
                currency: currencyCode,
                value: pricing[0].cost,
                items
              })

              this.$rs.track("Course Upgraded")
              // Track via GTM
              this.$gtm.push({
                event: "120_upgrade",
              })
            }

            // Auto apply discount
            if(priceData.offerCode !== ''){
              console.log('Auto-applying discount...',priceData.offerCode)
              await dispatch('addDiscountServer',priceData.offerCode);
            }

            // Add free product if one is included in offer and not already in cart
            const FP = this.state.cart.added.find(p => p.id === Number(optionsData.freeproduct_id))
            if(optionsData.free_offer === '1' && optionsData.freeproduct_id && (priceData.product.type.includes('course') || priceData.product.type.includes('bundle')) && !FP) {
              commit('setCartLoading', true)
              const freeproductData = {
                order_id: orderId,
                product_id: Number(optionsData.freeproduct_id),
              }
              const productId = {
                id: Number(optionsData.freeproduct_id) 
              }
              
              // Call add again for free product
              const freeResult = await this.$axios.$post(
                process.env.DATA_URL + '/orders/add_product/',
                freeproductData
              )
              console.log('Adding free product...'. freeproductData)
              await dispatch('addToCartFree',productId)
              // Update the cart totals
              const freeTotalsData = {
                full:freeResult.boughtProductTotal,
                discounted:freeResult.boughtProductTotalDiscounted,
                due:freeResult.totalDue,
                paid:result.totalPaid
              }
              commit('setCartLoading', false)
              commit('setCartTotals', freeTotalsData)
              commit('setServerCart', freeResult)
            }

            
            commit('setCartLoading', false)
            return result

          } catch (error) {
            console.log('Add to cart error: ',error)
            await dispatch('setCartError',{
              id: priceData.product.id,
              error: error.response.data
            })
            return error.response.data
          }



      }
    
    } else {
      // Item is already in the cart
      console.log('Item already in cart',priceData.product.id)
      // Do this anyway to update the message
      // commit('addToCart',priceData.product.id)
      await dispatch('addToCart',priceData.product)
    }
  },

  // Remove discount on server
  async removeDiscountServer({ commit }, discountCode){
    localStorage.removeItem('tefl_cs_store')
    commit('hideError')
    commit('setCartLoading', true)
    const orderId = this.state.cart.orderId;

    // Create the data to send
    const cartData = {
        order_id: orderId,
        // discount_code: discountCode
    };

    try {
      const result = await this.$axios.$post(
        process.env.DATA_URL + '/orders/remove_discount/',
        cartData
      )
      console.log('Remove discount on server: ',result)
      commit('removeDiscount')
      // Update the cart totals
      const totalsData = {
        full:result.boughtProductTotal,
        discounted:result.boughtProductTotalDiscounted,
        due:result.boughtProductTotalDiscounted-result.totalPaid,
        paid:result.totalPaid
      }
      commit('setCartTotals', totalsData)
      commit('setServerCart', result)
      // TRACKNG
      if ( window ) {
        // Track via rudderstack
        this.$rs.track("Coupon Removed", {
            order_id: orderId,
            cart_id: orderId,
            coupon_id: discountCode,
            coupon_name: discountCode,
            discount: result.boughtProductTotal - result.boughtProductTotalDiscounted
        })
        // NO GTM EQUIVALENT FOR THIS
      }
      commit('setCartLoading', false)
      return result

    } catch (error) {
      const errorMessage = {
        type: 'discount',
        message: error.response.data
      }
      commit('setError', errorMessage)
      commit('setCartLoading', false)
      return error.response.data
    }
  },

  // Change the order currency on the server
  async changeCurrencyServer({ dispatch, commit }, priceLabel){
    localStorage.removeItem('tefl_cs_store')
    commit('hideError')
    commit('setCartLoading', true)
    commit('setRegionClicked', priceLabel)
    const orderId = this.state.cart.orderId;
    
    // Overwrite for ROW option
    if(priceLabel === 'ROW'){
      priceLabel = 'TEFLUS'
    }
    // Overwrite for EU option
    if(priceLabel === 'EU'){
      priceLabel = 'IRELAND'
    }

    if(orderId && this.state.cart.price_label !== priceLabel) {
      // Only need to send this to the server if there is an order
      // Create the data to send
      const cartData = {
          order_id: orderId,
          product_price_label: priceLabel
      };

      try {
        const result = await this.$axios.$post(
          process.env.DATA_URL + '/orders/set_product_price_label/',
          cartData
        )
        console.log('Change currency on server: ',result)
        commit('setPriceLabel', priceLabel)

        // STORE CURRENT DISCOUNT IN THIS CONST FOR USE LATER
        const offerCode = this.state.cart.discount.toUpperCase()

        // REMOVE THE DISCOUNT HERE AS IT WILL BE REMOVED ON THE SERVER
        commit('removeDiscount')
        // Update the cart totals
        const totalsData = {
          full:result.boughtProductTotal,
          discounted:result.boughtProductTotalDiscounted,
          due:result.boughtProductTotalDiscounted-result.totalPaid,
          paid:result.totalPaid
        }
        commit('setCartTotals', totalsData)
        commit('setServerCart', result)
        // Track via rudderstack
        this.$rs.track( "Region Changed", {
          priceLabel
        })
        commit('setCartLoading', false)

        // Auto apply discount
        if(offerCode !== ''){
          console.log('Auto-applying discount...',offerCode)
          await dispatch('addDiscountServer',offerCode);
        }

        const pathName = window.location.pathname;
        // const query = window.location.search;
        if(pathName === '/' && this.state.user.userRegion === 'en-us'){
          window.location.pathname = '/en-us/';
        }
        else if(pathName === '/en-us/' && this.state.user.userRegion === 'en'){
          window.location.pathname = '/';
        }
        // return result

      } catch (error) {
        const errorMessage = {
          type: 'region',
          message: error.response.data
        }
        commit('setError', errorMessage)
        commit('setCartLoading', false)
        return error.response.data
      }

    } else {
      commit('setPriceLabel', priceLabel)
      commit('setCartLoading', false)
      const pathName = window.location.pathname;
      // const query = window.location.search;
      if(pathName === '/' && this.state.user.userRegion === 'en-us'){
        window.location.pathname = '/en-us/';
      }
      else if(pathName === '/en-us/' && this.state.user.userRegion === 'en'){
        window.location.pathname = '/';
      }
    }
  },
}